import React from "react";
import { Link } from "gatsby";
import { OutboundLink } from "gatsby-plugin-google-gtag";
import styles from "./aboutAuthor.module.scss";

//import { StaticImage } from "gatsby-plugin-image"
// <StaticImage src={'tony.jpg'} alt={'Tony'} imgClassName={styles.aboutTony__img} width={289} height={290}  />

// import avatar_png from "./img/avatar.png";
// import avatar_md_png from "./img/avatar_md.png";
// import avatar_lg_png from "./img/avatar_lg.png";
// import avatar_xl_png from "./img/avatar_xl.png";
//
// import tony_png from "./img/tony.png";
// import tony_md_png from "./img/tony_md.png";
// import tony_lg_png from "./img/tony_lg.png";
// import tony_xl_png from "./img/tony_xl.png";
//
// import avatar from "./img/avatar.webp";
// import avatar_md from "./img/avatar_md.webp";
// import avatar_lg from "./img/avatar_lg.webp";
// import avatar_xl from "./img/avatar_xl.webp";

import tony_jpg from "./img/tony_sm.png"
import polina_jpg from "./img/polina_sm.png"
import danny_jpg from "./img/danny.jpg"
import ali_jpg from "./img/ali.jpg"
import style from "../contact/contact.module.scss"


// <picture>
// <source media="(min-width: 1140px)" srcSet={avatar_xl} type="image/webp"/>
//     <source media="(min-width: 1140px)" srcSet={avatar_xl_png} type="image/png"/>
//
//     <source media="(min-width: 960px)" srcSet={avatar_lg} type="image/webp"/>
//     <source media="(min-width: 960px)" srcSet={avatar_lg_png} type="image/png"/>
//
//     <source media="(min-width: 720px)" srcSet={avatar_md} type="image/webp"/>
//     <source media="(min-width: 720px)" srcSet={avatar_md_png} type="image/png"/>
//
//     <source media="(max-width: 719px)" srcSet={avatar} type="image/webp"/>
//     <img className={styles.aboutAuthor__img} src={avatar_png} alt="main banner" width="289px" height="286px"/>
//     </picture>
// <picture>
// <source media="(min-width: 1140px)" srcSet={tony_xl_png} type="image/png"/>
//
//     <source media="(min-width: 960px)" srcSet={tony_lg_png} type="image/png"/>
//
//     <source media="(min-width: 720px)" srcSet={tony_md_png} type="image/png"/>
//
//     <img className={styles.aboutTony__img} src={tony_png} alt="main banner" width="289px" height="290px"/>
//     </picture>



function AboutAuthor() {
  return (
      <>
        <div className={`${styles.aboutAuthor} container`}>
          <div className={styles.aboutAuthor__content}>

            <picture>
              <source media="(min-width: 1140px)" srcSet={polina_jpg} type="image/jpg"/>
              <source media="(min-width: 960px)" srcSet={polina_jpg} type="image/jpg"/>
              <source media="(min-width: 720px)" srcSet={polina_jpg} type="image/jpg"/>
              <img className={styles.aboutAuthor__img} src={polina_jpg} alt="Polina" height="289px"/>
            </picture>

            <p className={styles.aboutAuthor__imgText}>
              Polina Shneyderman, Lic Ac <OutboundLink href="https://nccaom.org">NCCAOM</OutboundLink> ®
              Diplomate of Oriental Medicine
            </p>
          </div>
          <div className={styles.aboutAuthor__text}>
            <p>Polina discovered her passion for Acupuncture and Chinese Medicine when she saw how much relief it
              provided
              to her loved ones with pain and chronic illnesses. </p>
            <br/>
            <p>Polina has a Masters Degree in Acupuncture and Oriental Medicine from the New England School of
              Acupuncture
              at Massachusetts College of Pharmacy and Health Sciences. She is licensed as an Acupuncturist and
              Herbalist
              in
              the state of Massachusetts and is recognized by the National Certification Commission for Acupuncture and
              Oriental Medicine (<OutboundLink href="https://nccaom.org">NCCAOM</OutboundLink>).</p>
            <br/>
            <p>Polina is a winner of <a href={'https://www.thebestvendor.com/wea23-wellness/'}>Women Entrepreneur 2023
              Award</a> in Health &amp; Wellness Category</p>
            <br/>
            <p>Polina treats both chronic and acute conditions. She offers balancing treatments to maintain health and
              wellbeing. Polina will give you her utmost care and attention and invite you to be an active participant
              in
              your treatment. She will work with you to diminish pain, balance your body and mind, and find your way to
              a
              healthier you. </p>
            <br/>
            <p>Polina accepts insurance. To check if your insurance benefits include acupuncture, please click <Link
                to="/#contacts">Contact Us</Link>.</p>
          </div>
        </div>
        <div className={`${styles.aboutAuthor} container`}>
          <div className={styles.aboutAuthor__content}>

            <picture>
              <source media="(min-width: 1140px)" srcSet={tony_jpg} type="image/jpg"/>
              <source media="(min-width: 960px)" srcSet={tony_jpg} type="image/jpg"/>
              <source media="(min-width: 720px)" srcSet={tony_jpg} type="image/jpg"/>
              <img className={styles.aboutTony__img} src={tony_jpg} alt="Tony" width="289px"/>
            </picture>

            <p className={styles.aboutAuthor__imgText}>
              Tony Beal, Lic Ac <OutboundLink href="https://nccaom.org">NCCAOM</OutboundLink> ®
              Diplomate of Oriental Medicine
            </p>
          </div>
          <div className={styles.aboutAuthor__text}>
            <p>Tony Beal is a graduate of New England School of Acupuncture at Massachusetts College of Pharmacy and
              Health
              Sciences. He has a Masters Degree in Acupuncture and Herbal Medicine with a focus in Japanese
              Acupuncture.</p>
            <br/>
            <p>Tony always had a deep fascination with nature and medicinal plants and believes that the study of local
              herbs can lead to a better understanding of herbs from other traditions.
              Tony’s studies in herbal medicine led him to acupuncture school where he fell in love with acupuncture’s
              ability to treat pain and emotional imbalance.</p>
            <br/>
            <p>Tony’s goal as a practitioner of East Asian Medicine is to provide patients with access to affordable,
              safe,
              and effective healthcare. He practices acupuncture as well as Magnet Therapy, LED/LASER therapy, Chinese
              herbal medicine, and Korean hand therapy.</p>
            <br/>
            <p>Tony accepts insurance. To check if your insurance benefits include acupuncture, please click <Link
                to="/#contacts">Contact Us</Link>.</p>
          </div>
        </div>
        <div className={`${styles.aboutAuthor} container`}>
          <div className={styles.aboutAuthor__content}>

            <picture>
              <source media="(min-width: 1140px)" srcSet={ali_jpg} type="image/jpg"/>
              <source media="(min-width: 960px)" srcSet={ali_jpg} type="image/jpg"/>
              <source media="(min-width: 720px)" srcSet={ali_jpg} type="image/jpg"/>
              <img className={styles.aboutAli__img} src={ali_jpg} alt="Ali" width="436px" height="400px"/>
            </picture>

            <p className={styles.aboutAuthor__imgText}>
              Ali Buzzeo, Lic Ac <OutboundLink href="https://nccaom.org">NCCAOM</OutboundLink> ®
              Diplomate of Oriental Medicine
            </p>
          </div>
          <div className={styles.aboutAuthor__text}>
            <p>Ali Buzzeo holds a Masters in Acupuncture from the New England School of Acupuncture at Massachusetts
              College of Pharmacy and Health Sciences, as well as a Masters in Psychology from New York University.</p>
            <br/>
            <p>While working as a child behavioral therapist, Ali observed that patients responded more effectively to
              sensory therapies than traditional methods, which led her to explore holistic healing and
              energy medicine.</p>
            <br/>
            <p>“I started to recognize the body as a gateway to regulating the nervous system, which sparked my interest
              in
              energy medicine and acupuncture.”</p>
            <br/>
            <p>Ali helps patients find emotional and physical balance through acupuncture, rooted in mind-body
              connection.
              Her approach combines spirituality, psychology, and energy medicine. In addition to anxiety, depression,
              and
              other emotional challenges, Ali treats both acute and chronic pain, insomnia, and digestive disorders.</p>
            <br/>
            <p>Ali accepts insurance. To check if your insurance benefits include acupuncture, please click <Link
                to="/#contacts">Contact Us</Link>.</p>
          </div>
        </div>
        <div className={`${styles.aboutAuthor} container`}>
          <div className={styles.aboutAuthor__content}>

            <picture>
              <source media="(min-width: 1140px)" srcSet={danny_jpg} type="image/jpg"/>
              <source media="(min-width: 960px)" srcSet={danny_jpg} type="image/jpg"/>
              <source media="(min-width: 720px)" srcSet={danny_jpg} type="image/jpg"/>
              <img className={styles.aboutDanny__img} src={danny_jpg} alt="Tony" width="267px" height="400px"/>
            </picture>

            <p className={styles.aboutAuthor__imgText}>
              Danny Catarius, Practice Manager
            </p>
            <p><a className={style.aboutAuthor__imgText}
                  href="mailto:&#100;&#97;&#110;&#110;&#121;&#64;&#97;&#99;&#117;&#98;&#111;&#115;&#116;&#111;&#110;&#46;&#99;&#111;&#109;">&#100;&#97;&#110;&#110;&#121;&#64;&#97;&#99;&#117;&#98;&#111;&#115;&#116;&#111;&#110;&#46;&#99;&#111;&#109;</a>
            </p>
            <p className={styles.aboutAuthor__imgText}>&#8481; 1-617-675-6940</p>
          </div>
          <div className={styles.aboutAuthor__text}>
            <p>
              With experience in systems management, Danny brings a valuable skillset and a shared devotion to holistic
              well-being.
              He ensures our clinics' efficient operation and is committed to elevating the AcuBoston community's
              overall experience.
            </p>
            <br/>
            <p>
              You will see Danny in clinic checking patients in and out, filling herbal prescriptions and facilitating
              health insurance verifications.
            </p>
          </div>
        </div>
      </>
  )
}

export default AboutAuthor